.text-field {
  position: relative;
}

.text-field .MuiInputLabel-outlined {
  transform: translate(14px, -6px) scale(0.75);
  background: white;
  top: -4px;
  padding: 0px 2.5px 0 3px;
}

.text-field .MuiOutlinedInput-input {
  padding: 13.5px 14px !important;
}

.new-select {
  padding-top: 6px;
  padding-bottom: 8px;
  position: relative;
}

.new-select div[class*="-menu"] {
  margin-top: -15px;
  z-index: 2;
}

#react-select-2-placeholder,
div[class*="-placeholder"] {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.flotting-label {
  font-size: 12px !important;
  padding: 5px !important;
  position: absolute;
  top: -15px;
  background: #fff;
  left: 9px;
  z-index: 1;
}

.filters .flotting-label {
  padding: 1px 5px !important;
  top: 9px;
}

.position-relative {
  position: relative !important;
}

.py-2 {
  padding-top: 0.6rem !important;
}
