.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  position: relative;
  overflow: hidden; /* Hide overflow from pseudo-element */
}

.flex-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  pointer-events: none;
  z-index: -1; /* Place the pseudo-element behind the content */
}

.text-center {
  text-align: center;
  margin: 10px;
  cursor: default;
}

.text-center h1,
.text-center h3 {
  margin: 10px;
  cursor: default;
}

.text-center h1 {
  font-size: 8em;
  transition: font-size 200ms ease-in-out;
  border-bottom: 1px dashed white;
}

.text-center h1 span#digit1 { animation-delay: 200ms; }
.text-center h1 span#digit2 { animation-delay: 300ms; }
.text-center h1 span#digit3 { animation-delay: 400ms; }

.text-center button {
  border: 1px solid white;
  background: transparent;
  outline: none;
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  transition: background-color 200ms ease-in;
  margin: 20px 0;
}

.text-center button:hover {
  background-color: white;
  color: #555;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
