html,body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  /* background-color: #FFD580; */
}

.App {
  flex: 1;
}

footer {
  margin-top: auto;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.position-relative {
  position: relative !important;
}

.py-2 {
  padding-top: 0.6rem !important;
}



/* Default styles */
/* Default styles */

/* Small screens (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }

  .p-3 {
    padding: 10px;
  }
  .new-select div[class*="-menu"] {
    margin-top: -15px;
    z-index: 2;
  }

  /* Add more styles as needed for smaller screens */
}

/* Medium screens (tablets, 600px to 900px) */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  body {
    font-size: 16px;
  }
  .new-select div[class*="-menu"] {
    margin-top: -15px;
    z-index: 2;
  }
  .p-3 {
    padding: 15px;
  }
  .form{
    padding: 10px;
  }

  /* Add more styles as needed for medium screens */
}

/* Large screens (laptops/desktops, 900px and up) */
@media only screen and (min-width: 901px) {
  body {
    font-size: 18px;
  }
  .new-select div[class*="-menu"] {
    margin-top: -15px;
    z-index: 2;
  }
  .p-3 {
    padding: 20px;
  }

  /* Add more styles as needed for larger screens */
}




